import React from "react";
import Layout from "../components/layout";
import PageHeader from "../components/PageHeader/PageHeader";
import InfoBox from "../components/InfoBox";
import Helmet from "react-helmet";

import { PageContainer, PageContent, Section } from "../styles";
import Footer from "../components/Footer";

export default function Success() {
  return (
    <Layout>
      <Helmet>
        <html lang="en" />
        <title>Thank You - Peace By Piece CBT</title>
        <meta
          name="description"
          content="I like to work with you as an individual, not with you as a label,
            so that you are looking at yourself as a unique person - not a
            diagnosis."
        />
      </Helmet>
      <PageContainer>
        <PageHeader title="Contact" description="Get in touch" />

        <PageContent>
          <div className="container">
            <div className="row">
              <div className="col col-lg-12">
                <Section>
                  <InfoBox>
                    <p>
                      Thank you! I have successfully received your enquiry and will
                      respond within 24 hours.
                    </p>
                  </InfoBox>
                </Section>
              </div>
            </div>
          </div>

          <div style={{ background: "rgb(30, 36, 54)" }}>
            <Footer contactForm={false} />
          </div>
        </PageContent>
      </PageContainer>
    </Layout>
  );
}
